<template>
  <div class="proof-of-insurance">
    <h3>{{ $t('onboarding.proof_of_insurance.title') }}</h3>

    {{ $t('onboarding.proof_of_insurance.text') }}

    <b-form @submit.prevent="onSubmit">

      <b-row class="m-t-md">
        <b-col class="text-center" cols="12">
          <generic-input type="file" multiple :accept="acceptedFileFormats" @input="(file) => files.push(file)" max-file-size="25000000">
            <div class="input-file-box">
              {{ $t('onboarding.proof_of_insurance.upload_button') }}<br>
              <font-awesome-icon icon="upload" class="m-t"/>
            </div>
          </generic-input>
        </b-col>
        <b-col v-for="file in currentUserImages" cols="12">
          {{ file.filename }}
        </b-col>
        <b-col v-for="(file,index) in files" cols="12">
          {{ file.name }}
          <b-button class="no-borders" variant="default"
                    @click.prevent="$delete(files, index)">
            <font-awesome-icon icon="times-circle"/>
          </b-button>
        </b-col>
      </b-row>

      <b-row class="m-t-md">
        <b-col>
          <generic-input v-model="checkboxValue"
                         label="onboarding.proof_of_insurance.checkbox_self_insurance"
                         type="checkbox"/>
        </b-col>
      </b-row>

      <b-button class="m-b m-t-md block full-width" type="submit" variant="primary" :disabled="!isSubmitButtonEnabled">
        {{ $t('button.save') }}
      </b-button>

    </b-form>

    {{ $t('onboarding.proof_of_insurance.footer') }}
  </div>
</template>

<script>
import GenericInput from "@/components/generic_input.vue";
import {mapGetters} from "vuex";
import FileInput from "@/components/file_input.vue";
import {putOnboardingClient} from "@/services/onboarding";
import store from "@/store";
import {getEmployee} from "@/services/employees";
import {EventBus} from "@/eventbus";

export default {
  components: {FileInput, GenericInput},
  beforeMount() {
    this.form.insurance_type = this.current_user.client.insurance_type
  },
  data() {
    return {
      acceptedFileFormats: ["jpeg", "jpg", "png", "pdf"],
      inputs: [
        [
          {
            key: 'insurance_type',
            label: 'onboarding.proof_of_insurance.checkbox_self_insurance',
            type: 'checkbox',
            required: false
          }
        ]
      ],
      files: [],
      form: {
        insurance_type: 0,
        proof_of_insurance_images: []
      }
    }
  },
  computed: {
    ...mapGetters("user", ["current_user"]),
    checkboxValue: {
      get: function () {
        return this.form.insurance_type === 0
      },
      set: function (newValue) {
        this.form.insurance_type = newValue ? 0 : -1
      }
    },
    currentUserImages() {
      return this.current_user.client.proof_of_insurance_images
    },
    isSubmitButtonEnabled() {
      return this.checkboxValue || this.currentUserImages.length > 0 || this.files.length > 0
    }
  },
  methods: {
    async onSubmit(event) {
      // trigger progress spinner to include processing time of base64 parsing
      EventBus.$emit("toggleProgressSpinner", true)
      this.form.proof_of_insurance_images.splice(0)
      for (const file of this.files) {
        await this.$parseFileToBase64(file).then(base64 => {
          this.form.proof_of_insurance_images.push(
              {
                filename: file.name,
                file_base64: base64
              }
          )
        }).catch(error => {
          // TODO: handle error
        })
      }
      await putOnboardingClient(this.current_user.client.id, this.form).then(response => {
        // download user to reflect changes
        getEmployee().then(response => {
          store.dispatch("user/setUser", response)
          this.$router.push({name: 'onboarding_dashboard'})
        })
      }).catch()
    },
  }
}
</script>

<style scoped lang="scss">

</style>


